export enum VideoCallStatus {
  Attended = 'attended',
  NoShow = 'no-show',
  Rescheduled = 'rescheduled',
}

export enum AllowedPlace {
  Home = 'home',
  Other = 'other',
}

export interface PlaceOfService {
  place_of_service: string;
  id: number;
}

export interface PlaceOfServiceRecord {
  memberId: number;
  scheduleId: number;
  authToken: string;
  place: AllowedPlace;
}

export type VideoCallStatusParams = {
  scheduleId: number;
  providerId: number;
  providerAuthToken: string;
  videoCallStatus: VideoCallStatus;
};

export interface CoreProviderConfig {
  provider: {
    id: number;
    email: string;
    scribe_config: {
      view_notes: boolean;
      generate_notes: boolean;
    };
  };
  scheduling: {
    view_recording: boolean;
  };
}

export interface ProviderConfig {
  provider: {
    id: number;
    email: string;
    scribeConfig: {
      viewNotes: boolean;
      generateNotes: boolean;
    };
  };
  scheduling: {
    viewRecording: boolean;
  };
}
