import { UserType } from '../video/types';
import { AllFeatures, Features } from './client';

const INITIAL_MEDICAL_APPOINTMENT_TOKEN = 'in-md-';

export default class FeatureManager {
  public static shouldRenderOutlineForAppointment({
    feature,
    userType,
    appointmentType,
  }: {
    feature: AllFeatures[Features.MembersWebAppShowOutline];
    userType: UserType;
    appointmentType: string | undefined;
  }): boolean {
    return (
      feature.enabled &&
      userType === UserType.Provider &&
      !!appointmentType?.toLowerCase().includes(INITIAL_MEDICAL_APPOINTMENT_TOKEN)
    );
  }
}
