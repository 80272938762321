import {
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import featuresManager, { Features } from '../../lib/features/client';
import { FeaturesActions, FeaturesActionTypes, FeaturesContextValue, FeaturesState } from './types';

const FeaturesContext = createContext<FeaturesContextValue | undefined>(undefined);

const initialState: FeaturesState = {
  [Features.MemberShowTelemedicineModalByClinic]: { enabled: false },
  [Features.MembersWebAppHideForbiddenDates]: { enabled: false },
  [Features.MembersWebAppShowOutline]: { enabled: false },
  loaded: false,
};

const featuresReducer: Reducer<FeaturesState, FeaturesActionTypes> = (
  prevState,
  action
): FeaturesState => {
  const { type, payload } = action;

  switch (type) {
    case FeaturesActions.SetAll:
      return { ...(payload || prevState), loaded: true };

    default:
      return prevState;
  }
};

export const FeaturesProvider: FC<PropsWithChildren> = ({ children }): ReactElement => {
  const [featuresState, dispatchFeatures] = useReducer(featuresReducer, initialState);

  useEffect(() => {
    (async () => {
      const features = await featuresManager.getFeatures();

      dispatchFeatures({ type: FeaturesActions.SetAll, payload: features });
    })();
  }, []);

  return (
    <FeaturesContext.Provider value={{ featuresState, dispatchFeatures }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures = (): FeaturesContextValue => {
  const featuresContext = useContext(FeaturesContext);

  if (!featuresContext) {
    throw new Error('featuresContext must be used within a FeaturesContext.Provider');
  }

  return featuresContext;
};
